// i18next-extract-mark-ns-start 404

import {graphql} from 'gatsby';
import React from 'react';
import { Trans, useTranslation } from "react-i18next";
import Layout from '../components/layout';
import Seo from '../components/atoms/seo';
import Center from '../components/atoms/center';

const NotFoundPage = () => {
  const {t} = useTranslation();
  return (
    <Layout>
      <Seo title={t('404: Not found')} />
      <Center>
      <h1>
        <Trans>NOT FOUND</Trans>
      </h1>
      <p>
        <Trans>You just hit a route that doesn't exist</Trans>
      </p>
      </Center>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
